document.addEventListener('turbolinks:load', () => {
    const toastOption = {
        animation: true,
        autohide: true,
        delay: 5000,
    }
    const toastElList = [].slice.call(document.querySelectorAll(".toast"))
    toastElList.forEach((toastEl) => {
        return new bootstrap.Toast(toastEl, toastOption).show()
    })
})
